/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import extensionRoutes from "./extension";
Vue.use(Router);

const routes = [
    ...extensionRoutes,
    {
        name: "settings",
        path: "/company/:company_id/settings",
        component: () =>
            import("../views/settings-screen.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    {
        path: "/company/:company_id/edit-location",
        name: "edit_location",
        component: () =>
            import("../views/edit-location.vue"),
        props: true,
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    {
        path: "/company/:company_id/edit-product",
        name: "edit_product",
        component: () =>
            import("../views/edit-product.vue"),
        props: true,
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    {
        path: "/company/:company_id/locations",
        component: () =>
            import("../views/locations.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    {
        path: "/company/:company_id/manual-trigger",
        component: () =>
            import("../views/logsStats.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    // {
    //     path: "/company/:company_id/logs",
    //     component: () =>
    //         import("../views/logs.vue")
    //     // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    // },
    {
        path: "/:catchAll(.*)",
        component: () =>
            import("../views/Not-found.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/Not-Found.vue")
    },

]
export default function createRouter() {
    return new Router({
        mode: 'history',
        routes
    })
}


module.exports = [
    // {
    //     path: "/company/:company_id/settings",
    //     // beforeEnter: (to, from, next) => {
    //     //     checkSubscription(to, from, next);
    //     // },
    //     component: () =>
    //         import("../views/settings.vue")
    //     // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    // },
    {
        path: "/company/:company_id/products",
        // beforeEnter: (to, from, next) => {
        //     checkSubscription(to, from, next);
        // },
        component: () =>
            import("../views/products-screen.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    },
    {
        path: `/company/:company_id/pricing`,
        component: () =>
            import(
                /* webpackChunkName: "extensions" */
                "../views/pricing.vue"
            ),
        meta: {
            name: "Pricing page"
        },
    },
    {
        path: `/company/:company_id/subscription-status`,
        component: () =>
            import(
                /* webpackChunkName: "extensions" */
                "../views/subscription-status.vue"
            ),
        meta: {
            name: "Subscription Status"
        },
    },
    {
        path: "/company/:company_id",
        name: "main",
        // beforeEnter: (to, from, next) => {
        //     checkSubscription(to, from, next);
        // },
        component: () =>
            import("../views/main.vue")
        // import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
    }
]
